import { useCallback, useEffect, useState } from "react";
import "./styles/base.scss";
// @ts-ignore
import zoomIn from "react-animations/lib/zoom-in";
import { StyleSheet, css } from "aphrodite";
// @ts-ignore
import { ModalManager } from "react-dynamic-modal";
import ProfileModal from "./components/ProfileModal";

interface Server {
  name: string;
  currentPlayers: number;
  maxPlayers: number;
  serverConnect: string;
  queue: number;
}

const staffTeam = [
  {
    name: "combattag",
    role: "owner / developer",
    img: "avatar_combattag.png",
    discordHandle: "CombatTag#0001",
    steamUrl: "https://steamcommunity.com/id/CombatTag/",
  },
  {
    name: "grim",
    role: "head admin",
    img: "avatar_grim_circle.png",
    steamUrl: "https://steamcommunity.com/id/gri16m/",
  },
  {
    name: "chinchillacat",
    role: "admin",
    img: "avatar_chincillacat_circle.png",
    steamUrl: "https://steamcommunity.com/profiles/76561198985596242/",
  },
  {
    name: "egg",
    role: "Admin",
    img: "avatar_egg_circle.png",
    steamUrl: "https://steamcommunity.com/profiles/76561198043216510",
  },
  {
    name: "secret",
    role: "Admin",
    img: "avatar_secret_circle.png",
    steamUrl: "https://steamcommunity.com/profiles/76561198263619912/",
  },
  {
    name: "paper",
    role: "admin",
    img: "avatar_paper_circle.png",
    steamUrl: "https://steamcommunity.com/profiles/76561198082190930",
  },
  {
    name: "trekn",
    role: "admin",
    img: "avatar_trekn_circle.png",
    steamUrl: "https://steamcommunity.com/id/treknit/",
  },
  {
    name: "daisy",
    role: "moderator",
    img: "avatar_bird_circle.png",
    steamUrl: "https://steamcommunity.com/id/fruitloopyies/",
  },
  {
    name: "david kellerman",
    role: "moderator",
    img: "avatar_david_circle.png",
    steamUrl: "https://steamcommunity.com/id/DavidKellerman/",
  },
];

function App() {
  let [showBack, setShowBack] = useState(false);
  let [loader, setLoader] = useState(true);
  let [menuOpened, setMenuOpened] = useState(false);
  const loadingServer = {
    name: "Loading",
    currentPlayers: 0,
    maxPlayers: 1,
    serverConnect: "",
    queue: 0,
  };
  let [serverInfo, setServerInfo] = useState<Server[]>([
    loadingServer,
    loadingServer,
    loadingServer,
  ]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setShowBack(window.scrollY > 10);
    });

    window.addEventListener("load", () => {
      setLoader(false);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 10);
    });

    fetch("https://ctgames.us:40000").then(res => {
      return res.json()
    }).then(async (data: any) => {
      setServerInfo(data);
    });
  }, []);

  const styles = StyleSheet.create({
    zoomIn: {
      animationName: zoomIn,
      animationDuration: "1s",
      animationDelay: "500ms",
    },
  });

  const openModal = (admin: any) => {
    ModalManager.open(
      <ProfileModal admin={admin} onRequestClose={() => true} />
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <div
          className="loader"
          style={{
            opacity: loader ? "1.0" : "0.0",
            visibility: loader ? undefined : "hidden",
          }}
        >
          <div className="spinner">
            <div className="cube1"></div>
            <div className="cube2"></div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: showBack ? "#fff" : undefined,
            width: "100%",
            zIndex: 300,
            boxShadow: showBack ? "0 0 10px 0 rgba(0, 0, 0, 0.3)" : "",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            transition: "all 0.25s ease",
          }}
        >
          <a
            href=""
            style={{
              marginRight: "0",
              marginLeft: "2rem",
              marginTop: showBack ? "1.5rem" : "2.5rem",
              marginBottom: showBack ? "1.5rem" : "2.5rem",
              transition: "all 0.25s ease",
            }}
          >
            <img
              className="logo_white"
              src="logo.png"
              alt="ctgames logo"
              style={{ display: showBack ? "none" : "inline-block" }}
            ></img>
            <img
              className="logo_dark"
              src="logo_dark.png"
              alt="ctgames logo"
              style={{
                display: showBack ? "inline-block" : "none",
                zIndex: 3000,
              }}
            ></img>
          </a>
          <div
            className="nav-menu"
            style={{
              visibility: menuOpened ? "visible" : "hidden",
              opacity: menuOpened ? 1.0 : 0,
            }}
          >
            <div
              className="nav-menu-close"
              onClick={() => setMenuOpened(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                focusable="false"
                className="ion ion-md-close"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 512 512"
                style={{ transform: "rotate(360deg)" }}
              >
                <path
                  d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            <h1 className="text-light">Menu</h1>
            <a href="#">Home</a>
            <a href="https://donate.ctgames.us/category/1452300">Store</a>
            <a href="https://discord.com/invite/qJ7gJHV">Discord</a>
          </div>
          <ul
            style={{
              marginRight: "2rem",
              marginLeft: "2rem",
              marginTop: showBack ? "1.5rem" : "2.5rem",
              marginBottom: showBack ? "1.5rem" : "2.5rem",
              transition: "all 0.25s ease",
            }}
            className={
              showBack
                ? "nav list-reset nav-text"
                : "nav list-reset text-light nav-text"
            }
          >
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="https://donate.ctgames.us/category/1452300">Store</a>
            </li>
            <li>
              <a href="https://discord.com/invite/qJ7gJHV">Discord</a>
            </li>
          </ul>
          <ul
            style={{
              marginRight: "2rem",
              marginLeft: "2rem",
              marginTop: showBack ? "1.5rem" : "2.5rem",
              marginBottom: showBack ? "1.5rem" : "2.5rem",
              transition: "all 0.25s ease",
            }}
            className={
              showBack
                ? "nav list-reset nav-icons"
                : "nav list-reset text-light nav-icons"
            }
            onClick={() => setMenuOpened(true)}
          >
            <li style={{ verticalAlign: "middle" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                focusable="false"
                width="1.5em"
                height="1.5em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 512 512"
                style={{
                  transform: "rotate(360deg)",
                  fill: !showBack ? "#fff" : "#1a1a1a",
                }}
              >
                <path
                  d="M64 384h384v-42.666H64V384zm0-106.666h384v-42.667H64v42.667zM64 128v42.665h384V128H64z"
                  fill="currentColor"
                ></path>
              </svg>
            </li>
          </ul>
        </div>
      </header>

      <div
        className={`${css(styles.zoomIn)}`}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          flexWrap: "wrap",
          position: "absolute",
          top: 0,
          width: "100%",
          animationFillMode: "backwards",
        }}
      >
        <h1
          className={`text-light title`}
          style={{
            fontWeight: 800,
            letterSpacing: "10px",
            textShadow: "0 0 15px rgba(0, 0, 0, 0.6)",
          }}
        >
          CTGames
        </h1>

        <p
          className="text-light sub-title"
          style={{
            textTransform: "uppercase",
            letterSpacing: "10px",
            textShadow: "0 0 15px rgba(0, 0, 0, 0.6)",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          arena based pvp server
        </p>

        <a
          href="https://donate.ctgames.us/category/1452300"
          className="btn_foevis btn_foevis_stroke_white btn_slider animjq opacity_zero animated zoomIn opacity_one main-button"
        >
          purchase vip
        </a>
      </div>
      <div className="background-container">
        <img className="background-img" src="background.png"></img>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        style={{ marginBottom: "0", zIndex: -3, marginTop: "-5px" }}
      >
        <filter id="dropShadow">
          <feDropShadow
            dx="0"
            dy="3"
            stdDeviation="7"
            floodOpacity="0.6"
          ></feDropShadow>
        </filter>
        <path
          fill="#1a1a1a"
          fillOpacity="1"
          d="M0,64L48,69.3C96,75,192,85,288,106.7C384,128,480,160,576,165.3C672,171,768,149,864,117.3C960,85,1056,43,1152,48C1248,53,1344,107,1392,133.3L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          style={{ filter: "url(#dropShadow)" }}
        ></path>
      </svg>
      <h1
        style={{
          textAlign: "center",
          fontFamily: "'Poppins', sans-serif",
          marginBottom: "3.5rem",
        }}
      >
        Connect
      </h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexFlow: "row wrap",
          maxWidth: "1200px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {serverInfo.map((server, index) => {
          return (
            <div
              key={`server-${server.name}-${index}`}
              style={{
                boxShadow:
                  "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                height: "250px",
                minWidth: "300px",
                padding: "2rem",
                borderRadius: ".5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "1rem",
                marginBottom: "5rem",
              }}
            >
              <p
                style={{
                  marginTop: "0",
                  fontSize: "28px",
                  textAlign: "center",
                }}
              >
                {server.name}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignContent: "space-between",
                  }}
                >
                  <p
                    style={{ marginTop: "0", fontWeight: 600, marginBottom: 0 }}
                  >
                    {server.serverConnect}
                  </p>
                  <div style={{ marginLeft: "auto", marginRight: 0 }}>
                    <p
                      style={{
                        marginTop: "0",
                        fontWeight: 600,
                        marginBottom: 0,
                        display: "inline",
                        color: "#b2b2b2",
                      }}
                    >
                      {server.currentPlayers - server.queue} /{" "}
                      {server.maxPlayers}{" "}
                    </p>
                    {server.queue > 0 ? (
                      <div
                        style={{
                          display: "inline",
                          borderRadius: "1rem",
                          boxShadow: "0px 0px 2.5px 0 rgba(0, 0, 0, 0.2)",
                          fontSize: "12px",
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          paddingRight: "5px",
                          paddingLeft: "5px",
                        }}
                      >
                        {server.queue} queued
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <progress
                  id="file"
                  value={Math.round(
                    (server.currentPlayers / server.maxPlayers) * 100.0
                  )}
                  max="100"
                  style={{ width: "100%" }}
                ></progress>{" "}
              </div>

              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "inline-block",
                }}
              >
                <a
                  style={{
                    display: "inline-block",
                    background: "transparent",
                    padding: "8px",
                    textAlign: "center",
                    backgroundColor: "#0090ff",
                    color: "#fff",
                    borderRadius: "5rem",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    boxShadow:
                      "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                  }}
                  href={`steam://connect/${server.serverConnect}`}
                >
                  Connect
                </a>
              </div>
            </div>
          );
        })}
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <filter id="dropShadow2">
          <feDropShadow
            dx="0"
            dy="-3"
            stdDeviation="7"
            floodOpacity=".2"
          ></feDropShadow>
        </filter>
        <path
          fill="#f7f7f7"
          fillOpacity="1"
          d="M0,64L48,69.3C96,75,192,85,288,106.7C384,128,480,160,576,165.3C672,171,768,149,864,117.3C960,85,1056,43,1152,48C1248,53,1344,107,1392,133.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          style={{ filter: "url(#dropShadow2)" }}
        ></path>
      </svg>
      <section
        id="team"
        style={{ backgroundColor: "#f7f7f7", marginTop: "-1rem" }}
      >
        <h1
          style={{
            textAlign: "center",
            fontFamily: "'Poppins', sans-serif",
            marginBottom: "3.5rem",
          }}
        >
          Server Staff
        </h1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            flexFlow: "row wrap",
            maxWidth: "1200px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {staffTeam.map((admin, index) => {
            return (
              <a onClick={() => openModal(admin)}>
                <div
                  key={index}
                  style={{
                    marginBottom: "0",
                    marginTop: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: "2rem",
                    height: "300px",
                    paddingBottom: "5rem",
                  }}
                >
                  <div className="col-md-4 member">
                    <img
                      style={{
                        height: "auto",
                        width: "250px",
                      }}
                      src={admin.img}
                      alt="member"
                    ></img>
                    <h4>{admin.name}</h4>
                    <p>{admin.role}</p>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </section>
    </div>
  );
}

export default App;
