import "../styles/base.scss";

//@ts-ignore
import { Effect, Modal, ModalManager } from "react-dynamic-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ProfileModal = (props: any) => {
  const { admin, onRequestClose } = props;

  return (
    <Modal
      onRequestClose={onRequestClose}
      effect={Effect.ScaleUp}
      style={{
        content: {
          width: "unset !important",
          maxWidth: "700px",
          position: "relative",
        },
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "2rem",
          right: "2rem",
        }}
      >
        <button
          style={{
            width: "40px",
            height: "40px",
            border: "none",
            background: "transparent",
          }}
          onClick={() => ModalManager.close()}
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
      </div>
      <section id="team">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "2rem",
            flexWrap: "wrap",
          }}
        >
          <div className="col-md-4 member">
            <img
              style={{
                height: "auto",
                width: "250px",
              }}
              src={admin.img}
              alt="member"
            ></img>
            <h4>{admin.name}</h4>
            <p>{admin.role}</p>
          </div>
          <div className="modal-socials" style={{ paddingLeft: "2rem" }}>
            {admin.discordHandle && (
              <div
                className="modal-socials-item"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  focusable="false"
                  width="30"
                  height="30"
                  style={{
                    paddingRight: "1rem",
                    msTransform: "rotate(360deg)",
                    WebkitTransform: "rotate(360deg)",
                    transform: "rotate(360deg)",
                  }}
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M20.222 0c1.406 0 2.54 1.137 2.607 2.475V24l-2.677-2.273l-1.47-1.338l-1.604-1.398l.67 2.205H3.71c-1.402 0-2.54-1.065-2.54-2.476V2.48C1.17 1.142 2.31.003 3.715.003h16.5L20.222 0zm-6.118 5.683h-.03l-.202.2c2.073.6 3.076 1.537 3.076 1.537c-1.336-.668-2.54-1.002-3.744-1.137c-.87-.135-1.74-.064-2.475 0h-.2c-.47 0-1.47.2-2.81.735c-.467.203-.735.336-.735.336s1.002-1.002 3.21-1.537l-.135-.135s-1.672-.064-3.477 1.27c0 0-1.805 3.144-1.805 7.02c0 0 1 1.74 3.743 1.806c0 0 .4-.533.805-1.002c-1.54-.468-2.14-1.404-2.14-1.404s.134.066.335.2h.06c.03 0 .044.015.06.03v.006c.016.016.03.03.06.03c.33.136.66.27.93.4a8.18 8.18 0 0 0 1.8.536c.93.135 1.996.2 3.21 0c.6-.135 1.2-.267 1.8-.535c.39-.2.87-.4 1.397-.737c0 0-.6.936-2.205 1.404c.33.466.795 1 .795 1c2.744-.06 3.81-1.8 3.87-1.726c0-3.87-1.815-7.02-1.815-7.02c-1.635-1.214-3.165-1.26-3.435-1.26l.056-.02zm.168 4.413c.703 0 1.27.6 1.27 1.335c0 .74-.57 1.34-1.27 1.34c-.7 0-1.27-.6-1.27-1.334c.002-.74.573-1.338 1.27-1.338zm-4.543 0c.7 0 1.266.6 1.266 1.335c0 .74-.57 1.34-1.27 1.34c-.7 0-1.27-.6-1.27-1.334c0-.74.57-1.338 1.27-1.338z"
                    fill="#7289da"
                  />
                </svg>

                <p>{admin.discordHandle}</p>
              </div>
            )}
            {admin.steamUrl && (
              <a href={admin.steamUrl} target="_blank" rel="noreferrer">
                <div
                  className="modal-socials-item"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    style={{ paddingRight: "1rem" }}
                  >
                    <path d="M24 12c0 6.627-5.373 12-12 12-5.782 0-10.608-4.091-11.744-9.537l4.764 2.003c.285 1.441 1.56 2.547 3.115 2.534 1.723-.017 3.105-1.414 3.116-3.129l.007-.003 3.602-2.684c2.304.024 4.14-1.833 4.14-4.091 0-2.26-1.834-4.093-4.093-4.093-2.234 0-4.048 1.791-4.09 4.015l-2.64 3.693c-.668-.014-1.269.169-1.791.51l-6.294-2.646c.708-5.953 5.765-10.572 11.908-10.572 6.627 0 12 5.373 12 12zm-16.577 5.477l-1.544-.649c.38.858 1.236 1.461 2.249 1.457 1.346-.012 2.422-1.11 2.41-2.455-.012-1.329-1.104-2.41-2.432-2.41-.287.001-.57.05-.86.16l1.542.648c.898.378 1.319 1.411.941 2.308-.376.896-1.409 1.318-2.306.941zm7.484-5.602c-1.533 0-2.781-1.249-2.781-2.782 0-1.534 1.248-2.782 2.781-2.782 1.534 0 2.781 1.248 2.781 2.782 0 1.533-1.247 2.782-2.781 2.782zm0-.682c1.159 0 2.1-.942 2.1-2.101 0-1.158-.94-2.102-2.1-2.102s-2.102.943-2.102 2.102c.001 1.159.943 2.101 2.102 2.101z" />
                  </svg>

                  <p>Steam Profile</p>
                </div>
              </a>
            )}
            {admin.youtubeUrl && (
              <a href={admin.youtubeUrl} target="_blank" rel="noreferrer">
                <div
                  className="modal-socials-item"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    style={{ paddingRight: "1rem", fill: "#FF0000" }}
                  >
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.441 16.892c-2.102.144-6.784.144-8.883 0-2.276-.156-2.541-1.27-2.558-4.892.017-3.629.285-4.736 2.558-4.892 2.099-.144 6.782-.144 8.883 0 2.277.156 2.541 1.27 2.559 4.892-.018 3.629-.285 4.736-2.559 4.892zm-6.441-7.234l4.917 2.338-4.917 2.346v-4.684z" />
                  </svg>

                  <p>Youtube</p>
                </div>
              </a>
            )}
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default ProfileModal;
